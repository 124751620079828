import React, {useEffect, useRef} from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

import BgEl from '../misc/bg-el.component';

const TitleBanner = ({title, subTitle, colour}) => {
  const titleRef = useRef(null);
  const textRef = useRef(null);
  const circle1 = useRef(null);
  const circle2 = useRef(null);
  const circle3 = useRef(null);
  const circle4 = useRef(null);

  const scrubValue = 0.8
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if ( titleRef && textRef && circle1 && circle2 && circle3 && circle4) {
      gsap.to(titleRef.current, {
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top " + titleRef.current.offsetTop,
          scrub: scrubValue,
        },
        opacity: 0,
        y: -15,
      })
      gsap.to(textRef.current, {
        scrollTrigger: {
          trigger: textRef.current,
          start: "top " + textRef.current.offsetTop,
          scrub: scrubValue,
        },
        opacity: 0,
        y: -30,
      })
      gsap.to(circle3.current, {
        scrollTrigger: {
          trigger: circle3.current,
          start: "top " + circle3.current.offsetTop,
          scrub: scrubValue,
        },
        y: 20,
        x: 5,
      })
      gsap.to(circle1.current, {
        scrollTrigger: {
          trigger: circle1.current,
          start:  "top " + circle1.current.offsetTop,
          scrub: scrubValue,
        },
        y: -40,
        x: 25,
      })
      gsap.to(circle2.current, {
        scrollTrigger: {
          trigger: circle2.current,
          start:  "top " + circle2.current.offsetTop,
          scrub: scrubValue,
        },
        y: -60,
        x: -30,
      })
      gsap.to(circle4.current, {
        scrollTrigger: {
          trigger: circle4.current,
          start:  "top " + circle4.current.offsetTop,
          scrub: scrubValue,
        },
        y: 80,
        x: -30,
      })
    }

  }, [ textRef, titleRef , circle1, circle2, circle3, circle4 ])

  return (
    <div className={`bg-gradient-to-b from-${colour}-light to-${colour}-dark mb-10 md:mb-20 lg:mb-28 relative overflow-hidden`}>
      <section className="max-w-screen-2xl mx-auto px-6 md:px-8">
        <header className="max-w-xl py-12 md:py-20 relative z-10">
          {subTitle && <p ref={textRef} className="text-white-200 leading-tight text-lg sm:text-2xl relative z-10" dangerouslySetInnerHTML={{__html: subTitle}} />}
          {title && <h1 ref={titleRef} className="text-white-default inline-block mb-4 text-4xl sm:text-5xl font-semibold leading-tight relative z-10" dangerouslySetInnerHTML={{__html: title}}/>}
          <div ref={circle3} className="absolute top-8 md:top-16 -left-12 select-none">
            <BgEl type="circle" colour="#705CA4" />
          </div>
        </header>
        <div ref={circle1} className="hidden md:block absolute -right-48 top-8 select-none">
          <BgEl type="rectangle" colour="#705CA4" opacity="opacity-50" />
        </div>
        <div ref={circle2} className="absolute right-24 md:right-64 md:bottom-12 select-none">
          <BgEl type="circle" colour="#3F2E7F" />
        </div>
        <div ref={circle4} className="hidden lg:block absolute -left-32 transform scale-75 -bottom-16 select-none">
          <BgEl type="rectangle" colour="#3F2E7F" opacity="opacity-50" />
        </div>
      </section>
    </div>
  );
}

export default TitleBanner;