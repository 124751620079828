
import React from 'react'

export default (stars, colour = '#ffffff') => {
    let starList = []


    for(let i = 0; i < stars; i++) {
        starList.push(<svg className="mr-1 mb-0" xmlns="https://www.w3.org/2000/svg" width="22.811" height="21.861" viewBox="0 0 22.811 21.861">
            <path id="star_3_" data-name="star (3)" d="M22.752,8.752a1.21,1.21,0,0,0-1.044-.834l-6.584-.6-2.6-6.094a1.212,1.212,0,0,0-2.23,0l-2.6,6.093-6.585.6A1.214,1.214,0,0,0,.414,10.04L5.391,14.4,3.923,20.869a1.21,1.21,0,0,0,1.8,1.31l5.679-3.394,5.677,3.394a1.212,1.212,0,0,0,1.8-1.31L17.42,14.4,22.4,10.041a1.213,1.213,0,0,0,.356-1.289Zm0,0" transform="translate(0 -0.491)" 
            fill={colour} />
        </svg>) 
    }

    return starList && starList.map((star, i) => <span className="flex-none" key={i}>{star}</span>);
}